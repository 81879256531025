@font-face {
    font-family: "Poppins";
    src: url(../assets/fonts/Poppins-Thin.ttf);
    // src: local('Poppins'), url('../assets/fonts/Poppins-Thin.tff') format('tff');
    font-weight: 100;
}

@font-face {
    font-family: "Poppins";
    src: url(../assets/fonts/Poppins-Light.ttf);
    font-weight: 200;
}

@font-face {
    font-family: "Poppins";
    src: url(../assets/fonts/Poppins-Regular.ttf);
    font-weight: 400;
}

@font-face {
    font-family: "Poppins";
    src: url(../assets/fonts/Poppins-Medium.ttf);
    font-weight: 500;
}

@font-face {
    font-family: "Poppins";
    src: url(../assets/fonts/Poppins-SemiBold.ttf);
    font-weight: 600;
}

@font-face {
    font-family: "Poppins";
    src: url(../assets/fonts/Poppins-Bold.ttf);
    font-weight: 700;
}

@font-face {
    font-family: "Inter";
    src: url(../assets/fonts/Inter-Thin.ttf);
    font-weight: 100;
}

@font-face {
    font-family: "Inter";
    src: url(../assets/fonts/Inter-Light.ttf);
    font-weight: 200;
}

@font-face {
    font-family: "Inter";
    src: url(../assets/fonts/Inter-Regular.ttf);
    font-weight: 400;
}

@font-face {
    font-family: "Inter";
    src: url(../assets/fonts/Inter-Medium.ttf);
    font-weight: 500;
}

@font-face {
    font-family: "Inter";
    src: url(../assets/fonts/Inter-SemiBold.ttf);
    font-weight: 600;
}

@font-face {
    font-family: "Inter";
    src: url(../assets/fonts/Inter-Bold.ttf);
    font-weight: 700;
}

body {
    font-family: "Poppins", sans-serif !important;
}

// Here you can add other styles
.MuiButton-root {
    text-transform: unset !important;
}

.bg-primary {
    background-color: #9e9e9e !important;
}

.cursor-pointer {
    &:hover {
        cursor: pointer;
    }
}

// Breadcrumb
.breadcrumb-div {
    background-color: white;
    color: rgba(62, 62, 62, 0.8);
    font-weight: 500;
    font-size: 15px;
    height: 56px;
    font-family: 'Inter';
}

.breadcrumb-div a {
    text-decoration: none;
    pointer-events: none;
    cursor: default;
    color: rgba(62, 62, 62, 0.8);
}

.breadcrumb-item {
    &.active {
        color: rgba(62, 62, 62, 0.8) !important;
    }
}

.text-error {
    color: rgb(255, 80, 80);
}

.cancel-search:hover {
    background-color: #dddddd;
}

// Mui
.MuiFilledInput-root {
    border-radius: 50px !important;

    &::before {
        border-bottom-style: none !important;
    }
}

.MuiFilledInput-root.Mui-error {
    box-shadow: rgb(255 80 80) 0 0 0 1px !important;
}

.MuiGrid-root.MuiGrid-item {
    padding-top: 14px;
}

.MuiInputBase-adornedEnd {
    &::after {
        border-bottom: none !important;
    }
}

.input-label {
    transform: translate(14px, 9px) scale(1) !important;

    &.Mui-focused {
        transform: translate(14px, -9px) scale(0.75) !important;
    }

    &.MuiFormLabel-filled {
        transform: translate(14px, -9px) scale(0.75) !important;
    }
}

.input-select {
    &.Mui-error {
        .MuiSelect-select {
            border-color: #d32f2f !important;
        }
    }
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.w-10 {
    width: 10% !important;
}

.w-15 {
    width: 15% !important;
}

.w-20 {
    width: 20% !important;
}

.w-25 {
    width: 25% !important;
}

.w-30 {
    width: 30% !important;
}

.w-40 {
    width: 40% !important;
}

.w-50 {
    width: 50% !important;
}

.pt-2 {
    padding-top: 20px !important;
}

.form-details-label {
    background: rgba(70, 104, 229, 0.1);
    padding: 8px;
    color: rgba(62, 62, 62, 1);
    font-size: 13px;
    font-weight: 500;
}

.form-details-value {
    padding-left: 8px;
    color: rgba(62, 62, 62, 1);
    font-weight: 400;
    opacity: 0.8;
}

.MuiDialogContent-root {
    /* hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none;
    scrollbar-width: none;

}

.MuiDialogContent-root::-webkit-scrollbar {
    display: none;

}

#main-menu {
    padding: 12px;

    div {
        color: #24272d;
        padding-top: 8px;
        padding-bottom: 8px;
    }

    a {
        color: #24272d;
        padding-top: 8px;
        padding-bottom: 8px;

        svg {
            color: #24272d;
        }
    }

    .active {
        color: #ffffff;
        background-color: #280B59;
        border-radius: 7px;
        padding-top: 8px;
        padding-bottom: 8px;

        svg {
            color: #ffffff;
        }
    }

    li {
        padding-top: 4px;
        padding-bottom: 4px;
    }
}

.MuiFormHelperText-root {
    margin-top: -10px !important;
}

.Mui-error.MuiFormHelperText-root  {
    width: fit-content !important;
    padding: 0 5px !important;
    background-color: white !important;
    z-index: 9 !important;
}

// sidebar toggle color
.sidebar-nav .nav-group-toggle::after {
    background-image: var(--cui-sidebar-nav-group-indicator, url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27rgba%280, 0, 0, 0.6%29%27%3e%3cpath fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e"));
}

.sidebar-nav .nav-link:hover.nav-group-toggle::after {
    background-image: var(--cui-sidebar-nav-group-indicator-hover, url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27rgba%280, 0, 0, 0.77%29%27%3e%3cpath fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e"))
}

.sidebar-nav .nav-link {
    font-size: 14px;
    color: #280B59 !important;
}

.sidebar-nav .nav-link.selected {
    font-size: 14px;
    color: #fff !important;
}

.sidebar-nav .nav-title {
    font-size: 14px;
    color: #A0A8C0;
    text-transform: none;
}

.sidebar-nav .nav-group.show {
    background: none;
}

.sidebar-nav {
    /* hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none;
    scrollbar-width: none;
    // box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    box-shadow: 4px 0px 4px rgba(40, 11, 89, 0.05);
    width: 250px;
    margin-top: 3px;
}

/* hide scrollbar for chrome, safari and opera */
.sidebar-nav::-webkit-scrollbar {
    display: none;
}

.sidebar {
    left: unset !important;
}

.icon-button-grey {
    border-color: transparent !important;
    background-color: rgba(52, 58, 64, 0.1) !important;
    color: #343a40 !important;
    width: 32px;
    height: 32px;
    font-size: 16px;
}

.icon-button-green {
    border-color: transparent !important;
    background-color: rgba(0, 128, 0, 0.1) !important;
    color: #343a40 !important;
    width: 32px;
    height: 32px;
    font-size: 16px;
}

.icon-button-red {
    border-color: transparent !important;
    background-color: rgba(255, 0, 0, 0.1) !important;
    color: red !important;
    width: 32px;
    height: 32px;
    font-size: 16px;
}

// icon button
.icon-btn:hover {
    color: white !important;
    background-color: #343a40 !important;
}

.icon-btn:focus {
    box-shadow: 0 0 0 0.15rem rgb(52 58 64 / 50%);
}

.table-title {
    color: #280B59 !important;
    font-size: 16px;
    font-weight: 400;
    padding: 12px !important;
    border-bottom: none;
}
.table-content {
    padding: 0.75rem 0.75rem !important;
    border-bottom: 1px solid rgba(217, 217, 217, 0.3) !important;
}

section {
    transition: border-color 0.3s ease;
  }
  
section.active {
    border: 5px solid #9747FF;
    border-radius: 10px;
}

.left-section::-webkit-scrollbar,
.right-section::-webkit-scrollbar {
  display: none; /* Hide the scrollbar */
}

::-webkit-scrollbar {
    width: 20px;
}

::-webkit-scrollbar-track {
    background-color: transparent;
}

::-webkit-scrollbar-thumb {
    background-color: #d6dee1;
}

::-webkit-scrollbar-thumb {
    background-color: #d6dee1;
    border-radius: 20px;
}

::-webkit-scrollbar-thumb {
    background-color: #d6dee1;
    border-radius: 20px;
    border: 6px solid transparent;
    background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
    background-color: #a8bbbf;
}

.two-line-text {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2; /* Limit to 2 lines */
    line-clamp: 2; /* Standard syntax */
}

.scrollbar-measure {
	width: 100px;
	height: 100px;
	overflow: scroll;
	position: absolute;
	top: -9999px;
}

// drag & drop overlay
.image-container {
    position: relative;
    display: inline-block;
}

.image {
    display: block;
    width: 100%;
    height: auto;
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 26px;
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    text-align: center;
}

.content {
    padding: 20px;
    font-weight: 700;
    font-size: 14px;
    line-height: 120%;
}